var counterInit     = false;

if (location.pathname === '/') {

	$(window).scroll(function() {
		var scrollTop = $(this).scrollTop();
		var offset = $('#homeNumbers').offset().top;
		var windowHeight = $(this).height();

		// verifica se a div está visível na tela e se a contagem ainda não foi iniciada
		if (scrollTop + windowHeight > offset && !counterInit) {
			counterInit = true; // atualiza a variável de controle
			var countNumArray = []; // array para armazenar os valores de countNum
			
			$('.counter').each(function() {
				var countTo = parseFloat($(this).data('number')); // valor final da contagem
				var elem    = $(this); // referência ao elemento atual

				// anima a contagem de 0 até countTo para cada elemento individualmente
				$({countNum: 0}).animate({countNum: countTo}, {
					duration: 1000,
					easing: 'linear',
					step: function(now, fx) {
						elem.text(Math.floor(now));
					},
					complete: function() {
						elem.text(countTo); // atualiza o valor final do elemento atual
					},
					elem: this // passa o elemento atual como parâmetro para o objeto animação
				});
			});
		}
	});

}

/**
 * Controla o slidehow da TV FEBRAC
 */
$('.slick-tv').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: '40px',
    dots: false,
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
});
$('.slick-tv').on( 'click', (e) => {
    let idYouTube        = e.target.getAttribute('data-youtubeid');
    const tvModal        = new bootstrap.Modal('#tvModal');
    const tvModalContent = document.querySelector("#tvModal");
    let tvTitle          = document.querySelector('#tvModalLabel');
    let tvFrame          = document.querySelector("#ytVideo");

    tvTitle.textContent  = e.target.getAttribute('data-title');;
    tvFrame.setAttribute( 'src', 'https://www.youtube.com/embed/'+idYouTube+'?start=1&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer&origin='+window.location.href );
    tvModal.show();

    tvModalContent.addEventListener('hidden.bs.modal', () => {
        tvFrame.setAttribute( 'src', '' );
    });

});

if (location.pathname === '/') {
	
	const maps = {
		selectMap: document.querySelector("#selectMap"),

		init: function() {
			let mapOptions = {
				zoom: 23,
				disableDefaultUI: true,
				zoomControl: true,
				panControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false, 
				overviewMapControl: false, 
				center: new google.maps.LatLng(-15.80105263496891, -47.88395495747903)
			};
			map 		= new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
			myLatLng 	= new google.maps.LatLng(-15.80105263496891, -47.88395495747903);
			beachMarker = new google.maps.Marker({
				position: myLatLng,
				map: map,
				icon: `${window.location.href}wp-content/themes/febrac/dist/img/map-marker.png`,
			});
		},

		addEvent: function() {
			this.selectMap.addEventListener( 'change', () => {
				let postId = this.selectMap.value;
				let option = this.selectMap.options[this.selectMap.selectedIndex];
				let phone  = option.dataset.phone;
				phone = phone.split("|");
				let sindicatePhonesHtml = '';

				for( let i = 0; i < phone.length-1; i++ ) {
					let sindicatePhonesLink = phone[i].replace(/\D/g, "");
					let sindicatePhones     = phone[i];
					sindicatePhonesHtml    += `<a href=tel:"${sindicatePhonesLink}" class="text-white">${sindicatePhones}</a>`;
					if( i < phone.length-1) {
						sindicatePhonesHtml    += ' | ';
					}
				}
				sindicatePhonesHtml = sindicatePhonesHtml.slice(0, sindicatePhonesHtml.length - 2);

				document.querySelector("#sindicateTitle").textContent 		= option.dataset.title;
				document.querySelector("#sindicateAddress").textContent 	= option.dataset.address;
				document.querySelector("#sindicateSite").href 				= option.dataset.site;
				document.querySelector("#sindicateSite").textContent 		= option.dataset.site;
				document.querySelector("#sindicateEmail").textContent 		= option.dataset.email;
				document.querySelector("#sindicateEmail").href 				= 'mailto:' + option.dataset.email;
				document.querySelector("#sindicatePhone").innerHTML 		= sindicatePhonesHtml;
				document.querySelector("#sindicateResponsible").textContent = option.dataset.responsible;
				document.querySelector("#btnRota").href 					= `https://www.google.com/maps?q=${option.dataset.lat},${option.dataset.lon}`;
				
				let mapOptions = {
					zoom: 23,
					disableDefaultUI: true,
					zoomControl: true,
					panControl: false,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false, 
					overviewMapControl: false, 
					center: new google.maps.LatLng(option.dataset.lat, option.dataset.lon)
				};
				map 		= new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
				myLatLng 	= new google.maps.LatLng(option.dataset.lat, option.dataset.lon);
				beachMarker = new google.maps.Marker({
					position: myLatLng,
					map: map,
					icon: `${window.location.href}wp-content/themes/febrac/dist/img/map-marker.png`,
				});
			});
		},
	}

	function initMap() {
		maps.init();
		maps.addEvent();
	}

}

const formObject = {
	formElement: document.querySelector('.form-footer'),
	btnSubmit: document.querySelector('#btnSubmit'),
	toastAlert: document.querySelector('.toast-alert'),
	toastName: document.querySelector('.toast-name'),
	toastTime: document.querySelector('.toast-time'),
	toastBody:document.querySelector('.toast-body'),
	toastLive: document.getElementById('liveToast'),
	toastBootstrap: '',

	handleSubmit: function() {
		this.formElement.addEventListener('submit', function(event) {
		  	event.preventDefault();
			this.btnSubmit.setAttribute('disabled', true);
			this.btnSubmit.innerHTML = '<i class="fa-solid fa-spinner fa-spin"></i> Enviando...';
			const data = {
				name: document.querySelector('#name').value,
				email: document.querySelector('#email').value,
				subject: document.querySelector('#subject').value,
				message: document.querySelector('#message').value
			};

			formObject.toastBootstrap = bootstrap.Toast.getOrCreateInstance(formObject.toastLive);

			formObject.sendEmail( data );
		});
	},
	sendEmail: function(data) {
		let urlApi = window.location.href + 'wp-json/send-email/send';
		// Faz a requisição para a URL que envia o email
		fetch(urlApi, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			'Content-Type': 'application/json'
			}
		})
		.then(response => {
			if (response.ok) {
				// O email foi enviado com sucesso
				this.btnSubmit.removeAttribute('disabled');
				this.btnSubmit.innerHTML = 'Enviar mensagem';

				formObject.toastAlert.classList.remove('toast-alert__danger');
				formObject.toastAlert.classList.add('toast-alert__success');
				formObject.toastName.textContent = " Formulário";
				formObject.toastTime.textContent = 'Agora';
				formObject.toastBody.textContent = "E-mail enviado com sucesso! Obrigado.";

				formObject.toastBootstrap.show();

				formObject.formElement.reset();
			} else {
				// Houve um erro no envio do email
				formObject.toastAlert.classList.remove('toast-alert__success');
				formObject.toastAlert.classList.add('toast-alert__danger');
				formObject.toastName.textContent = " Formulário";
				formObject.toastTime.textContent = 'Agora';
				formObject.toastBody.textContent = "Houve um erro ao enviar seu e-mail. Por favor tente mais tarde. Obrigado!";

				formObject.toastBootstrap.show();
			}
		})
		.catch(error => {
			// Houve um erro na requisição
			console.log('Houve um erro' + error);
		});
	}
}

formObject.handleSubmit();